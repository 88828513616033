import React from 'react';

const Frontend = () => {
    return (
        <div className="skills_content">
            <h3 className="skills_title">Développeur Front</h3>
            <div className="skills_box">
                <div className="skills_group">
                    <div className="skills_data">
                        <i className='bx bx-badge-check'></i>
                        <div>
                            <h3 className="skills_name">HTML</h3>
                            <span className="skills_level">Avancé</span>
                        </div>
                    </div>

                    <div className="skills_data">
                        <i className='bx bx-badge-check'></i>
                        <div>
                            <h3 className="skills_name">CSS</h3>
                            <span className="skills_level">Avancé</span>
                        </div>
                    </div>

                    <div className="skills_data">
                        <i className='bx bx-badge-check'></i>
                        <div>
                            <h3 className="skills_name">JavaScript</h3>
                            <span className="skills_level">Intermédiaire</span>
                        </div>
                    </div>
                </div>

                <div className="skills_group">
                    <div className="skills_data">
                        <i className='bx bx-badge-check'></i>
                        <div>
                            <h3 className="skills_name">React</h3>
                            <span className="skills_level">Base</span>
                        </div>
                    </div>

                    <div className="skills_data">
                        <i className='bx bx-badge-check'></i>
                        <div>
                            <h3 className="skills_name">Wordpress</h3>
                            <span className="skills_level">Base</span>
                        </div>
                    </div>

                    <div className="skills_data">
                        <i className='bx bx-badge-check'></i>
                        <div>
                            <h3 className="skills_name">Git</h3>
                            <span className="skills_level">Base</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Frontend